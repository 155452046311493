import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { history } from './_helpers'
import { authenticationService } from './_services'
import { PrivateRoute } from './_components'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { split, HttpLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink } from '@apollo/client'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './Components/ErrorFallBack'
import { Container } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay'
import { Provider } from 'react-redux'
import { store } from './Components/redux/store'
import Alert from './Components/Common/Alert/Alert'

// Lazy Imports
const CustomerSatTable = lazy(
	() => import('./Components/MyAccount/CustomerSatTable'),
)
const CustomerSatReport = lazy(
	() => import('./Components/MyAccount/CustomerSatReport'),
)
const PeguTable = lazy(() => import('./Components/MyAccount/PeguTable'))
const PeguReport = lazy(() => import('./Components/MyAccount/PeguReport'))
const AddressChangeReport = lazy(
	() => import('./Components/MyAccount/AddressChangeReport'),
)
const BacklogUploader = lazy(
	() => import('./Components/MyAccount/BacklogUploader'),
)
const BacklogTable = lazy(() => import('./Components/MyAccount/BacklogTable'))
const PhysicalCardReport = lazy(
	() => import('./Components/MyAccount/PhysicalCardReport'),
)
const VirtualCardReport = lazy(
	() => import('./Components/MyAccount/VirtualCardReport'),
)
const CSREditPayment = lazy(
	() => import('./Components/MyAccount/CSREditPayment'),
)

// Components
const LoginPage = lazy(() => import('./Components/Login/Login'))
const Dashboard = lazy(() => import('./Components/DashBoard/Dashboard'))
const Uploader = lazy(() => import('./Components/MyAccount/Uploader'))
const AllTypeTable = lazy(() => import('./Components/MyAccount/AllTypeTable'))
const AllStatusReport = lazy(
	() => import('./Components/MyAccount/AllStatusReport'),
)
const LeadOutComeReport = lazy(
	() => import('./Components/MyAccount/LeadOutComeReport'),
)
const ResendEmail = lazy(() => import('./Components/MyAccount/ResendEmail'))
const Forgot = lazy(() => import('./Components/Forgot/Forgot'))
const ResetPwd = lazy(() => import('./Components/MyAccount/ResetPwd'))
const CheckReport = lazy(() => import('./Components/MyAccount/CheckReport'))
const FundOrder = lazy(() => import('./Components/MyAccount/FundOrder'))
const EditPayment = lazy(() => import('./Components/MyAccount/EditPayment'))
const UnRedeemedReport = lazy(
	() => import('./Components/MyAccount/UnRedeemedReport'),
)
const ChangeInfoReport = lazy(
	() => import('./Components/MyAccount/ChangeInfoReport'),
)
const CheckOrder = lazy(() => import('./Components/MyAccount/CheckOrder'))
const AddUser = lazy(() => import('./Components/MyAccount/AddUser'))
const UserTable = lazy(() => import('./Components/MyAccount/UserTable'))
const RebateSSO = lazy(() => import('./Components/RebateSSO/RebateSSO'))
const CampaignFileUploader = lazy(
	() => import('./Components/MyAccount/CampaignFileUploader'),
)
const InvoiceOrder = lazy(() => import('./Components/MyAccount/InvoiceOrder'))
const DisqualifiedUploader = lazy(
	() => import('./Components/MyAccount/DisqualifiedUploader'),
)
const EnergyConsultantUploader = lazy(
	() => import('./Components/MyAccount/EnergyConsultantUploader'),
)
const EnergyConsultant = lazy(
	() => import('./Components/Energyconsultant/EnergyConsultant'),
)
const PromotionsUploader = lazy(
	() => import('./Components/MyAccount/PromotionsUploader'),
)
const ResendLeadsConduit = lazy(
	() => import('./Components/ResendLeadsConduit/ResendLeadsConduit'),
)
const Report1099 = lazy(() => import('./Components/Reports/Report1099'))
const ProcessedPaymentReport = lazy(
	() => import('./Components/Reports/ProcessedPaymentReport'),
)

const httpLink = createUploadLink({
	uri: process.env.REACT_APP_NODE_URL,
	headers: { 'Apollo-Require-Preflight': 'true' },
})

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem('currentUser')
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
			email: localStorage.getItem('emailToken'),
			loggedInUserId: localStorage.getItem('userId'),
		},
	}
})
const wsLink = new GraphQLWsLink(
	createClient({
		url: process.env.REACT_APP_NODE_URL_WSS,
		options: {
			reconnect: true,
		},
	}),
)

//wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () => wsLink.subscriptionClient.maxConnectTimeGenerator.max;

/*const link = split(
  ({ query }) => {
	const { kind, operation } = getMainDefinition(query)
	return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  logoutLink.concat(authLink.concat(httpLink))
)*/
const errorlink = onError(
	({ graphQLErrors, networkError, operation, forward, response }) => {
		if (
			graphQLErrors &&
			!graphQLErrors[0].message.includes('Empty Report') &&
			!graphQLErrors[0].message.includes('Lead already success')
		) {
			graphQLErrors.map(({ message, locations, path }) =>
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
				),
			)
			authenticationService.logout()
			history.push('/login')
			window.location = '/login'
		}

		if (networkError) {
			console.log(`[Network error]: ${networkError}`)
			console.log(response)
			authenticationService.logout()
			history.push('/login')
			window.location = '/login'
		}
	},
)

const httpLinkErrHandling = ApolloLink.from([errorlink, httpLink])

const link = split(
	({ query }) => {
		const { kind, operation } = getMainDefinition(query)
		return kind === 'OperationDefinition' && operation === 'subscription'
	},
	wsLink,
	authLink.concat(httpLinkErrHandling),
)
/*const client = new ApolloClient({
  link: logoutLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache()
});*/
const client = new ApolloClient({
	link: link,
	cache: new InMemoryCache(),
})

/*function App() {
  return (
	<ApolloProvider client={client}>
	  <React.Fragment>
		<Router>
		  <NavigationBar />
		  <Jumbotron />
		  <Uploader />
		</Router>
	  </React.Fragment>
	</ApolloProvider>
  );
}*/
class App extends React.Component {
	logOutTime

	constructor(props) {
		super(props)

		this.state = {
			currentUser: null,
		}
	}

	componentDidMount() {
		authenticationService.currentUser.subscribe((x) =>
			this.setState({ currentUser: x }),
		)
		this.autoLogout()
	}

	logout() {
		authenticationService.logout()
		history.push('/login')
		localStorage.clear()
	}

	setTime = () => {
		this.logOutTime = setTimeout(() => {
			if (window.location.pathname !== '/login') {
				this.logout()
			}
		}, 300 * 1000)
	}

	resetTime = () => {
		this.clearTimeEvent()
		this.setTime()
	}

	clearTimeEvent = () => {
		if (this.logOutTime) {
			clearTimeout(this.logOutTime)
		}
	}

	autoLogout = () => {
		let events = ['load', 'mousemove', 'mousedown', 'scroll', 'keypress']
		for (const ev of events) {
			window.addEventListener(ev, (e) => {
				this.resetTime()
			})
		}
		this.setTime()
	}

	render() {
		const { currentUser } = this.state
		return (
			<Suspense
				fallback={
					<>
						<LoadingOverlay
							active={true}
							spinner
							styles={{
								wrapper: {
									width: '100vw',
									height: '100vh',
								},
							}}
							fadeSpeed={0}
							text={'Loading...'}
						>
							<Container className="min-vh-100 min-vw-100 d-flex justify-content-center align-items-center"></Container>
						</LoadingOverlay>
					</>
				}
			>
				<Provider store={store}>
					<Alert />
					<ErrorBoundary FallbackComponent={ErrorFallback}>
						<ApolloProvider client={client}>
							<React.Fragment>
								<Router history={history}>
									<PrivateRoute exact path="/add-user" component={AddUser} />
									<PrivateRoute exact path="/users" component={UserTable} />
									<PrivateRoute exact path="/uploader" component={Uploader} />
									<PrivateRoute
										exact
										path="/oneoffsuploader"
										component={CampaignFileUploader}
									/>
									<PrivateRoute
										exact
										path="/disqualified-uploader"
										component={DisqualifiedUploader}
									/>
									<PrivateRoute
										exact
										path="/energyconsultantuploader"
										component={EnergyConsultantUploader}
									/>
									<PrivateRoute
										exact
										path="/promotionsuploader"
										component={PromotionsUploader}
									/>
									<PrivateRoute
										exact
										path="/cussattable"
										component={CustomerSatTable}
									/>
									<PrivateRoute exact path="/pegutable" component={PeguTable} />
									<PrivateRoute exact path="/" component={Dashboard} />
									<Route path="/login" component={LoginPage} />
									<PrivateRoute
										exact
										path="/alltypetable"
										component={AllTypeTable}
									/>
									<Route path="/rebatesso" component={RebateSSO} />
									<Route exact path="/forgotpwd" component={Forgot} />
									<PrivateRoute
										exact
										path="/cussatreport"
										component={CustomerSatReport}
									/>
									<PrivateRoute
										exact
										path="/pegureport"
										component={PeguReport}
									/>
									<PrivateRoute
										exact
										path="/addresschangereport"
										component={AddressChangeReport}
									/>
									<PrivateRoute
										exact
										path="/alltypereport"
										component={AllStatusReport}
									/>
									<PrivateRoute
										exact
										path="/lead-report"
										component={LeadOutComeReport}
									/>
									<PrivateRoute
										exact
										path="/resendemail"
										component={ResendEmail}
									/>
									<PrivateRoute
										exact
										path="/backloguploader"
										component={BacklogUploader}
									/>
									<PrivateRoute
										exact
										path="/energyconsultant"
										component={EnergyConsultant}
									/>
									<PrivateRoute
										exact
										path="/backlogtable"
										component={BacklogTable}
									/>
									<PrivateRoute exact path="/resetPwd" component={ResetPwd} />
									<PrivateRoute
										exact
										path="/checkreport"
										component={CheckReport}
									/>
									<PrivateRoute
										exact
										path="/physicalreport"
										component={PhysicalCardReport}
									/>
									<PrivateRoute
										exact
										path="/virtualreport"
										component={VirtualCardReport}
									/>
									<PrivateRoute exact path="/fundOrder" component={FundOrder} />
									<PrivateRoute
										exact
										path="/invoiceorder"
										component={InvoiceOrder}
									/>
									<PrivateRoute
										exact
										path="/editPayment"
										component={EditPayment}
									/>
									<PrivateRoute
										exact
										path="/unredeemedreport"
										component={UnRedeemedReport}
									/>
									<PrivateRoute
										exact
										path="/csreditPayment"
										component={CSREditPayment}
									/>
									<PrivateRoute
										exact
										path="/changeInfoReport"
										component={ChangeInfoReport}
									/>
									<PrivateRoute
										exact
										path="/checkOrder"
										component={CheckOrder}
									/>
									<PrivateRoute
										exact
										path="/resendleadsconduit"
										component={ResendLeadsConduit}
									/>
									<PrivateRoute
										exact
										path="/report1099"
										component={Report1099}
									/>
									<PrivateRoute
										exact
										path="/processedPaymentReport"
										component={ProcessedPaymentReport}
									/>
								</Router>
							</React.Fragment>
						</ApolloProvider>
					</ErrorBoundary>
				</Provider>
			</Suspense>
		)
	}
}

export default App
