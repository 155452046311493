export const cities = [
	{
		name: 'Alabama',
		value: 'AL',
	},
	{
		name: 'Alaska',
		value: 'AK',
	},
	{
		name: 'Arizona',
		value: 'AZ',
	},
	{
		name: 'Arkansas',
		value: 'AR',
	},
	{
		name: 'California',
		value: 'CA',
	},
	{
		name: 'Colorado',
		value: 'CO',
	},
	{
		name: 'Connecticut',
		value: 'CT',
	},
	{
		name: 'Delaware',
		value: 'DE',
	},
	{
		name: 'District of Columbia',
		value: 'DC',
	},
	{
		name: 'Florida',
		value: 'FL',
	},
	{
		name: 'Georgia',
		value: 'GA',
	},
	{
		name: 'Hawaii',
		value: 'HI',
	},
	{
		name: 'Idaho',
		value: 'ID',
	},
	{
		name: 'Illinois',
		value: 'IL',
	},
	{
		name: 'Indiana',
		value: 'IN',
	},
	{
		name: 'Iowa',
		value: 'IA',
	},
	{
		name: 'Kansas',
		value: 'KS',
	},
	{
		name: 'Kentucky',
		value: 'KY',
	},
	{
		name: 'Louisiana',
		value: 'LA',
	},
	{
		name: 'Maine',
		value: 'ME',
	},
	{
		name: 'Maryland',
		value: 'MD',
	},
	{
		name: 'Massachusetts',
		value: 'MA',
	},
	{
		name: 'Michigan',
		value: 'MI',
	},
	{
		name: 'Minnesota',
		value: 'MN',
	},
	{
		name: 'Mississippi',
		value: 'MS',
	},
	{
		name: 'Missouri',
		value: 'MO',
	},
	{
		name: 'Montana',
		value: 'MT',
	},
	{
		name: 'Nebaraska',
		value: 'NE',
	},
	{
		name: 'Nevada',
		value: 'NV',
	},
	{
		name: 'New Hampshire',
		value: 'NH',
	},
	{
		name: 'New Jersey',
		value: 'NJ',
	},
	{
		name: 'New Mexico',
		value: 'NM',
	},
	{
		name: 'New York',
		value: 'NY',
	},
	{
		name: 'North Carolina',
		value: 'NC',
	},
	{
		name: 'North Dakota',
		value: 'ND',
	},
	{
		name: 'Ohio',
		value: 'OH',
	},
	{
		name: 'Oklahoma',
		value: 'OK',
	},
	{
		name: 'Oregon',
		value: 'OR',
	},
	{
		name: 'Pennsylvania',
		value: 'PA',
	},
	{
		name: 'Rhode Island',
		value: 'RI',
	},
	{
		name: 'South Carolina',
		value: 'SC',
	},
	{
		name: 'South Dakota',
		value: 'SD',
	},
	{
		name: 'Tennessee',
		value: 'TN',
	},
	{
		name: 'Texas',
		value: 'TX',
	},
	{
		name: 'Utah',
		value: 'UT',
	},
	{
		name: 'Vermont',
		value: 'VT',
	},
	{
		name: 'Virginia',
		value: 'VA',
	},
	{
		name: 'Washington',
		value: 'WA',
	},
	{
		name: 'West Virginia',
		value: 'WV',
	},
	{
		name: 'Wisconsin',
		value: 'WI',
	},
	{
		name: 'Wyoming',
		value: 'WY',
	},
]

export const patterns = {
	specialCharacterCheckPatternWithSpace: /^[a-z0-9\s_.-]+$/i,
	specialCharacterCheckPattern: /^[a-z0-9_.-]+$/i,
	phoneNumberCheckPattern: /^(?:\d*)$/,
	passwordCheck: /^(?=.*?[A-Z])(?=.*?[#?!@$%^&*-]).{8,}$/,
	zipCheck: /^[0-9]*$/,
	specialCharacterPattern: /[`!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/,
}

export const paginaion = {
	pageSize: 10,
}

export const rewardTypes = {
	physical: 'visareward',
	virtual: 'virtualvisa',
	check: 'check',
}

export const displayRewardTypeName = {
	PHYSICAL_ALIAS: 'Physical Visa Reward Card',
	VIRTUAL_ALIAS: 'Virtual Visa Reward Card',
	CHECK_ALIAS: 'Check',
}

export const roleNames = {
	managmentAccess: 'BPS Management Level',
	bpsadmin: 'BPS Support Level',
	clientUser: 'Client Level',
	accountingFlag: 'Accounting',
}

export const roleValue = {
	managmentAccess: 'managmentAccess',
	bpsadmin: 'bpsadmin',
	clientUser: 'clientUser',
	accountingFlag: 'accountingFlag',
}

export const level = [
	{ value: 'managmentAccess', label: 'BPS Management Level' },
	{ value: 'bpsadmin', label: 'BPS Support Level' },
	{ value: 'clientUser', label: 'Client Level' },
	{ value: 'accountingFlag', label: 'Accounting' },
]

export const status = {
	active: 'active',
	inactive: 'inactive',
}

export const paymentAction = {
	VOIDED: 'VOIDED',
	REISSUED: 'REISSUED',
}

export const installationStatuses = {
	INSTALLED: 'INSTALLATION_COMPLETE',
	DISQUALIFIED: 'DISQUALIFIED',
	PENDING: 'PENDING',
}

export const ProgramNamesDashboard = [
	{
		value: 'Share SunPower - Customer Referrals',
		label: 'Share SunPower',
	},
	{
		value: 'Refer SunPower SPD',
		label: 'SPD',
	},
	{
		value: 'NEM Customer Comms 1',
		label: 'NEMS',
	},
	{
		value: 'SPD GTR',
		label: 'SPD GTR',
	},
]

export const ProgramNamesObject = {
	SPD: 'Refer SunPower SPD',
	SHARESUNPOWER: 'Share SunPower - Customer Referrals',
	NEMS: 'NEM Customer Comms 1',
}

export const MonthNamesArray = [
	{
		label: 'Entire Year',
		value: '-',
	},
	{
		label: 'January',
		value: '01',
	},
	{
		label: 'February',
		value: '02',
	},
	{
		label: 'March',
		value: '03',
	},
	{
		label: 'April',
		value: '04',
	},
	{
		label: 'May',
		value: '05',
	},
	{
		label: 'June',
		value: '06',
	},
	{
		label: 'July',
		value: '07',
	},
	{
		label: 'August',
		value: '08',
	},
	{
		label: 'September',
		value: '09',
	},
	{
		label: 'October',
		value: '10',
	},
	{
		label: 'November',
		value: '11',
	},
	{
		label: 'December',
		value: '12',
	},
]

export const monthNumberToName = {
	1: 'January',
	2: 'February',
	3: 'March',
	4: 'April',
	5: 'May',
	6: 'June',
	7: 'July',
	8: 'August',
	9: 'September',
	10: 'October',
	11: 'November',
	12: 'December',
}
