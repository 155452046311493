import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./uiSlice/uiSlice";

const store = configureStore({
  reducer: {
    ui: uiSlice,
  },
});

export { store };
