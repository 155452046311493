import { createSlice } from '@reduxjs/toolkit'
import { ProgramNamesObject } from '../../Common/Constants'

const initialState = {
	selectedProgramName: ProgramNamesObject.SHARESUNPOWER,
	selectedYear: new Date().getFullYear(),
	selectedMonth: '-',
	alert: undefined,
}

const uiSlice = createSlice({
	name: 'uiSlice',
	initialState,
	reducers: {
		setProgramName: (state, { payload }) => {
			state.selectedProgramName = payload
		},
		setYearForDashboard: (state, { payload }) => {
			state.selectedYear = payload
		},
		setMonthForDashboard: (state, { payload }) => {
			state.selectedMonth = payload
		},
		setAlertState: (state, { payload }) => {
			state.alert = payload
		},
	},
})

export const {
	setProgramName,
	setYearForDashboard,
	setMonthForDashboard,
	setAlertState,
} = uiSlice.actions
export default uiSlice.reducer
