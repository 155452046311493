import { BehaviorSubject } from 'rxjs'
import { useMutation, gql } from '@apollo/client'
import { handleResponse } from '../_helpers'

import axios from 'axios'

const currentUserSubject = new BehaviorSubject(
	localStorage.getItem('currentUser'),
)

export const authenticationService = {
	login,
	logout,
	loginThroughToken,
	loginThroughRebateSSOToken,
	SSOLogin,
	currentUser: currentUserSubject.asObservable(),
	get currentUserValue() {
		return currentUserSubject.value
	},
}

const axiosGraphQL = axios.create({
	baseURL: process.env.REACT_APP_NODE_URL,
})

const loginQuery = (email, password) => `
  mutation {
    login(input: {email: "${email}", password: "${password}"}) {
      token
      fname
      lname
      title
      id
      phone
      bpsadminFlag
      managementAccessFlag
      superUser
      resetPwdFlag
      encryptedEmail
      accountingFlag
    }
  }
`

const loginToken = (vi, content) => `
mutation {
  setConnector(vi: "${vi}", content:"${content}") {
    token
    fname
    lname
    title
    phone
    resetPwdFlag
    bpsadminFlag
    superUser
    encryptedEmail
    id
  }
}
`

const rebateSSOToken = (encEmail) => `
mutation {
  rebateSSO(encEmail: "${encEmail}") {
    token
    fname
    lname
    title
    phone
    resetPwdFlag
    bpsadminFlag
    superUser
    encryptedEmail
    id
    managementAccessFlag
    accountingFlag
  }
}
`

const LoginFromSSO = (token) => `
 mutation {
  getUserDetailsViaToken(token:"${token}") {
    token
    fname
    lname
    title
    phone
    resetPwdFlag
    bpsadminFlag
    managementAccessFlag
    superUser
    encryptedEmail
    id
  }
} 
 `
const setDataInLocalStorage = (result) => {
	localStorage.setItem('currentUser', result.token)
	localStorage.setItem('superUser', result.superUser)
	localStorage.setItem('fname', result.fname)
	localStorage.setItem('lname', result.lname)
	localStorage.setItem('title', result.title)
	localStorage.setItem('phone', result.phone)
	localStorage.setItem('bpsadminFlag', result.bpsadminFlag)
	localStorage.setItem('resetPwdFlag', result.resetPwdFlag)
	localStorage.setItem('managementAccessFlag', result.managementAccessFlag)
	localStorage.setItem('accountingFlag', result.accountingFlag)
	localStorage.setItem('userId', result.id)
	localStorage.setItem('emailToken', result.encryptedEmail)
	currentUserSubject.next(result.token)
}

function login(email, password) {
	/*const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };*/
	//console.log(email,' : ',password);
	return new Promise((resolve, reject) => {
		try {
			axiosGraphQL
				.post('', {
					query: loginQuery(email, password),
				})
				.then((result) => {
					if (result.data.errors) {
						reject(result.data.errors[0].message)
					} else {
						setDataInLocalStorage(result.data.data.login)
						resolve(result.data.data.login.token)
					}
				})
		} catch (err) {
			console.log(err)
			console.log('failed')
			reject(err)
		}

		//return user;
	})
}

function logout() {
	// remove user from local storage to log user out
	localStorage.clear()
	currentUserSubject.next(null)
}

function loginThroughToken(vi, content) {
	return new Promise((resolve, reject) => {
		try {
			axiosGraphQL
				.post('', {
					query: loginToken(vi, content),
				})
				.then((result) => {
					if (result.data.errors) {
						reject(result.data.errors[0].message)
					} else {
						setDataInLocalStorage(result.data.data.setConnector)
						resolve(result.data.data.setConnector.token)
					}
				})
		} catch (err) {
			reject(err)
		}
	})
}

function loginThroughRebateSSOToken(encEmail) {
	return new Promise((resolve, reject) => {
		try {
			axiosGraphQL
				.post('', {
					query: rebateSSOToken(encEmail),
				})
				.then((result) => {
					if (result.data.errors) {
						reject(result.data.errors[0].message)
					} else {
						setDataInLocalStorage(result.data.data.rebateSSO)
						resolve(result.data.data.rebateSSO.token)
					}
				})
		} catch (err) {
			reject(err)
		}
	})
}

function SSOLogin(token) {
	return new Promise((resolve, reject) => {
		try {
			axiosGraphQL
				.post('', {
					query: LoginFromSSO(token),
				})
				.then((result) => {
					if (result.data.errors) {
						reject(result.data.errors[0].message)
					} else {
						setDataInLocalStorage(result.data.data.getUserDetailsViaToken)
						resolve(result.data.data.getUserDetailsViaToken.token)
					}
				})
		} catch (err) {
			reject(err)
		}
	})
}
